import Head from 'next/head';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import Link from 'next/link';
import cn from 'classnames';
import brandImage1 from '../../assets/images/landing_page_2.png';
import brandImage2 from '../../assets/images/concert_image.png';
import brandImage3 from '../../assets/images/wedding_image.png';

import templateFestival from '../../assets/images/section/Template_Festival.png';
import ticket from '../../assets/images/section/Ticket.png';
import maasai from '../../assets/images/section/Maasai.png';
import party from '../../assets/images/section/party.png';
import wedding from '../../assets/images/section/wedding.png';
import businessWorkshop from '../../assets/images/section/Business_Workshop.png';
import blackOne from '../../assets/images/section/Black-01.png';
import WeddingTemplate from '../../assets/images/section/Wedding_Template.png';
import tickets from '../../assets/images/section/tickets.png';
import ticketVerification from '../../assets/images/section/ticket_verification.png';
import payout from '../../assets/images/section/payout.png';
import attending from '../../assets/images/section/attending.png';
import party_h from '../../assets/images/section/party_h.png';
import africasasa from '../../assets/images/section/africasasa.svg';
import payment_methods from '../../assets/images/section/payment_methods.png';
import chat from '../../assets/images/section/Chat.png';

import Footer from './Footer';

const IMAGES = [brandImage1, brandImage2, brandImage3];

const Home = () => {
  const [imgIdx, setImgIdx] = useState(1);

  const handleChangeImage = direction => {
    const numberOfImages = IMAGES.length;

    setImgIdx(idx => {
      if (direction === '+' && idx === numberOfImages - 1) return 0;
      if (direction === '-' && idx === 0) return numberOfImages - 1;
      if (direction === '+') return idx + 1;
      if (direction === '-') return idx - 1;
    });
  };

  useEffect(() => {
    const carouselRoll = setInterval(() => handleChangeImage('+'), 3500);
    return () => clearInterval(carouselRoll);
  }, []);

  return (
    <>
      <Head>
        <title>Africa Sasa | Events Listing & Ticketing Platform</title>
        <meta
          name="description"
          content="Events Listing & Ticketing Platform"
        />
        <meta
          property="og:title"
          content="Africa Sasa | Events Listing & Ticketing Platform"
        />
        <meta
          property="og:description"
          content="Events Listing & Ticketing Platform"
        />
        <meta
          property="og:image"
          content="/images/landing_page_1.png"
          key="og:image"
        />
        <meta property="og:image:width" content="" />
        <meta property="og:image:height" content="" />
        <meta
          property="og:url"
          content={`${process.env.NEXT_PUBLIC_MERCHANT_WEB_URL}`}
        />
      </Head>
      <div className="h-[25rem] lg:h-[43.5rem] w-full bg-gray-50 overflow-hidden">
        <div className="h-full overflow-hidden relative">
          <div className="text-[36px] lg:text-[64px] absolute z-10 font-bold text-white left-[50px] lg:left-[76px] lg:w-1/2 top-[40%] lg:top-1/2 -translate-y-[50%]">
            <div className="mb-8">
              Event Listing, Ticketing & Messaging Platform
            </div>
            <div className="space-x-8">
              <Link href="/user/register">
                <a className="text-base bg-white text-black visited:text-black px-12 py-4 rounded-lg  border-2 border-white hover:bg-gray-100">
                  Get Started
                </a>
              </Link>
              <Link href="/user/login">
                <a className="text-base bg-transparent text-white visited:text-white px-12 py-4 rounded-lg border-2 border-white hover:border-gray-200">
                  Login
                </a>
              </Link>
            </div>
          </div>
          <div className="absolute h-full w-full bg-opacity-20">
            <Image
              src={IMAGES[imgIdx]}
              alt="Hero Image"
              layout="fill"
              objectFit="cover"
              objectPosition="right 5%"
              priority
              placeholder="blur"
              className="h-20"
            />
          </div>
          <div className="absolute bottom-5 flex items-center left-[76px] space-x-1">
            <div
              className="bg-gray-400 hover:bg-gray-200 flex items-center justify-center p-1 rounded cursor-pointer"
              onClick={() => handleChangeImage('-')}
            >
              <span className="material-icons">&#xe5cb;</span>
            </div>
            <div
              className="bg-gray-400 hover:bg-gray-200 flex items-center justify-center p-1 rounded cursor-pointer"
              onClick={() => handleChangeImage('+')}
            >
              <span className="material-icons">&#xe5cc;</span>
            </div>
          </div>
        </div>
      </div>
      <div className="px-[2rem] lg:px-[4.9rem] py-[2rem] lg:py-[6.4rem]">
        <div>
          <h2 className="font-semibold text-4xl lg:text-6xl lg:w-[62rem]">
            Event Listing, Ticketing & Messaging Platform
          </h2>
          <div className="flex mt-[4rem] lg:mt-[5.5rem] lg:px-14 w-full lg:w-auto overflow-x-scroll">
            <div className="h-[20rem] lg:h-[34rem] bg-gray-50 w-[12rem] lg:w-[24rem] mr-8 lg:mr-16 relative">
              <Image
                src={templateFestival}
                alt="Africa Sasa"
                layout="fill"
                objectFit="cover"
                placeholder="blur"
              />
            </div>
            <div className="h-[20rem] lg:h-[34rem] bg-gray-50 w-[11.5rem] lg:w-[19.4rem] mr-8 lg:mr-16 relative">
              <Image
                src={ticket}
                alt="Africa Sasa"
                layout="fill"
                objectFit="cover"
                placeholder="blur"
              />
            </div>
            <div className="h-[20rem] lg:h-[34rem] bg-gray-50 w-[12rem] lg:w-[21.9rem] relative">
              <Image
                src={maasai}
                alt="Africa Sasa"
                layout="fill"
                objectFit="cover"
                placeholder="blur"
              />
            </div>
          </div>
        </div>

        <div className="mt-[2rem] lg:mt-[12.5rem] flex justify-between space-x-8 flex-col lg:flex-row">
          <div className="mt-[3rem] lg:mt-[5.5rem]">
            <div className="text-3xl lg:text-5xl font-semibold lg:leading-[3.8rem]">
              A <span className="text-[#6E4097]">Platform</span> For{' '}
              <span className="text-[#6E4097]">Organisers</span> To{' '}
              <span className="text-[#6E4097]">Manage</span> Their Public Or
              Private <span className="text-[#6E4097]">Events</span>
            </div>
            <div className="mt-8 text-[#696871] max-w-[22rem] leading-[1.8rem]">
              A platform where you can create a public or private event, list
              the event, create tickets for the event, accept payments on Mobile
              Money and Credit Cards, issue tickets with a unique QR Code,
              verify the tickets at the gate as well as communicate with event
              attendees.
            </div>
          </div>
          <div className="flex space-x-8 mt-4 lg:mt-auto">
            <div className="h-[22rem] lg:h-[32rem] mt-[6rem] bg-gray-50 w-[16rem] relative">
              <Image
                src={party}
                alt="Africa Sasa"
                layout="fill"
                objectFit="cover"
                placeholder="blur"
              />
            </div>
            <div className="h-[22rem] lg:h-[32rem]  bg-gray-50 w-[16rem] relative">
              <Image
                src={wedding}
                alt="Africa Sasa"
                layout="fill"
                objectFit="cover"
                placeholder="blur"
              />
            </div>
          </div>
        </div>
        <div className="mt-[3rem] lg:mt-[10rem]">
          <div className="text-[#6E4097] text-4xl lg:text-6xl font-bold">
            <h2>How it works</h2>
          </div>
        </div>
      </div>

      <div className="relative mt-7 lg:mt-auto h-[30rem] lg:h-[36rem]">
        <div className="absolute -top-[2rem] lg:-top-[3.7rem] left-[4.6rem] z-10">
          <Numbering value="1" />
        </div>
        <div className="bg-[#72559D] absolute w-full h-full text-white px-[2rem] lg:px-[5.5rem] py-[4rem] lg:py-[7.1rem]">
          <div className="text-4xl lg:text-5xl font-semibold max-w-[60rem] lg:leading-[3.8rem] mb-8">
            With our help, create your own event poster or select from our
            templates
          </div>
          <div>
            We help you design a great-looking event poster by either choosing
            from our templates or creating your own
          </div>
        </div>
        <div className="flex space-x-[4.5rem] justify-between absolute left-[3rem] lg:left-[5.4rem] -bottom-[8rem] lg:-bottom-[21rem] z-10">
          <div className="bg-gray-50 h-[15rem] lg:h-[33.3rem] w-[10rem] lg:w-[23.5rem] relative">
            <Image
              src={businessWorkshop}
              alt="Africa Sasa"
              layout="fill"
              objectFit="cover"
              placeholder="blur"
            />
          </div>
          <div className="bg-gray-50 h-[12rem] lg:h-[24.2rem]  w-[10rem] lg:w-[23.5rem] relative">
            <Image
              src={blackOne}
              alt="Africa Sasa"
              layout="fill"
              objectFit="cover"
              placeholder="blur"
            />
          </div>
          <div className="bg-gray-50 h-[33.3rem] w-[23.5rem] relative hidden lg:block">
            <Image
              src={WeddingTemplate}
              alt="Africa Sasa"
              layout="fill"
              objectFit="cover"
              placeholder="blur"
            />
          </div>
        </div>
      </div>

      <div className="px-[1.5rem] lg:px-[5rem] mt-48 lg:mt-[31rem]">
        <div className="flex justify-between flex-col lg:flex-row">
          <div>
            <Numbering value="2" />
            <div className="space-y-4">
              <div className="py-10 px-2 lg:px-12 rounded-[2.5rem] bg-white max-w-[35.2rem]">
                <div className="font-semibold text-3xl mb-5">
                  <span className="text-[#6E4097]">List</span> An Event
                </div>
                <div className="leading-[2rem] text-[#696871]">
                  We help you design a great-looking event poster by either
                  choosing from our templates or creating your own
                </div>
              </div>
              <div className="py-10 px-12 rounded-[2.5rem] bg-white max-w-[35.2rem] shadow-[1px_1px_60px_#00000014]">
                <div className="font-semibold text-3xl mb-5">
                  <span className="text-[#6E4097]">Add</span> Tickets
                </div>
                <div className="leading-[2rem] text-[#696871]">
                  We help you design a great-looking event poster by either
                  choosing from our templates or creating your own
                </div>
              </div>
              <div className="py-10 px-12 rounded-[2.5rem] bg-white max-w-[35.2rem]">
                <div className="font-semibold text-3xl mb-5">
                  <span className="text-[#6E4097]">Promote</span> Your Event
                </div>
                <div className="leading-[2rem] text-[#696871]">
                  We help you design a great-looking event poster by either
                  choosing from our templates or creating your own
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 h-[40rem] lg:h-[51.8rem] w-full lg:w-[30.2rem] relative">
            <Image
              src={tickets}
              alt="Africa Sasa"
              layout="fill"
              objectFit="cover"
              placeholder="blur"
            />
          </div>
        </div>
        <div className="flex mt-[5rem] lg:mt-[8.3rem] lg:ml-[4.5rem]">
          <div className="bg-gray-50 h-[52rem] w-[30.5rem] mt-[11.7rem] mr-[8rem] relative hidden lg:block">
            <Image
              src={ticket}
              alt="Africa Sasa"
              layout="fill"
              objectFit="cover"
              placeholder="blur"
            />
          </div>
          <div className="lg:max-w-[34rem] space-y-12">
            <Numbering value="3" />
            <div className="font-semibold text-4xl lg:text-5xl">
              <span className="text-[#6E4097]">Issue</span> &{' '}
              <span className="text-[#6E4097]">Verify</span> Tickets
            </div>
            <div>
              Tickets are issued with a unique QR code. Add ticket verifiers who
              can scan ticket validity and admit attendees to event
            </div>
            <div className="bg-gray-50 lg:h-[42.2rem] h-[30rem] w-[20rem] lg:w-[32rem] relative">
              <Image
                src={ticketVerification}
                alt="Africa Sasa"
                layout="fill"
                objectFit="cover"
                placeholder="blur"
              />
            </div>
          </div>
        </div>
        <div className="flex mt-[7rem] lg:mt-[14rem] flex-col lg:flex-row">
          <div className="max-w-[45rem] space-y-10 mr-[3rem]">
            <Numbering value="4" />
            <div className="font-semibold text-4xl lg:text-[3.4rem]">
              <span className="text-[#6E4097]">Timely</span> &{' '}
              <span className="text-[#6E4097]">Flexible</span> Payments
            </div>
            <div>
              Collection of payments from attendees is made easy with different
              payment methods.
            </div>
            <div>
              Proceeds from your tickets can be withdrawn and are sent to your
              bank account within 1 Business Day after the event
            </div>
            <div className="relative h-[5rem] w-full">
              <Image
                src={payment_methods}
                alt="Africa Sasa"
                layout="fill"
                objectFit="cover"
                placeholder="blur"
              />
            </div>
          </div>
          <div className="bg-gray-50 h-[30rem] lg:h-[50rem] w-[20rem] lg:w-[32rem] relative">
            <Image
              src={payout}
              alt="Payout"
              layout="fill"
              objectFit="cover"
              placeholder="blur"
            />
          </div>
        </div>
        <div className="mt-[5rem] lg:mt-[11rem]">
          <div className="flex lg:items-center gap-8 px-8 lg:px-16 flex-col lg:flex-row">
            <Numbering value="5" />
            <div className="text-3xl lg:text-5xl font-semibold">
              Stay In Touch And{' '}
              <span className="text-[#6E4097]">Keep The Buzz Alive</span>
            </div>
          </div>
          <div className="flex mt-[3.2rem] lg:px-[3.5rem] flex-col lg:flex-row">
            <div className="bg-gray-50  h-[30rem] w-full lg:h-[48.8rem] lg:w-[32rem] mr-[9rem] relative">
              <Image
                src={maasai}
                alt="Payout"
                layout="fill"
                objectFit="cover"
                placeholder="blur"
              />
            </div>
            <div>
              <div className="max-w-[32.2rem] space-y-6 mb-8 mt-8 lg:mt-auto">
                <Point label="Create a mailing list for past attendees to follow & subscribe for your future events." />
                <Point label="Attendees can follow and subscribe to event organisers" />
                <Point label="Event organisers can message or create an Africa Sasa group where members can continue chatting after an event.." />
                <Point label="Communicate with, and update attendees after they have purchased tickets." />
              </div>
              <div className="bg-gray-50 h-[35rem] w-full relative">
                <Image
                  src={attending}
                  alt="Payout"
                  layout="fill"
                  objectFit="cover"
                  placeholder="blur"
                />
              </div>
            </div>
          </div>
          <div className="mt-[2rem] lg:mt-[13.5rem]">
            <div className="flex flex-col lg:flex-row gap-10">
              <div className="flex gap-8 lg:gap-12 flex-col lg:flex-row lg:items-center font-semibold mr-14">
                <Numbering value="6" className="max-content" />
                <span className="text-4xl lg:text-5xl max-w-lg tracking-wider">
                  Create A Chatsasa Group For Event Attendees
                </span>
              </div>
              <div className="max-w-md text-lg space-y-4">
                <Point label="Enable Event Attendees to Chat with you" />
                <Point label="Enable Event Attendees to Chat with each other" />
                <Point label="Post Content About your Event e.g. Topics with Text, Images, Videos or Links" />
                <Point label="Check Out www.ChatSasa.com for more info" />
              </div>
            </div>
            <div className="relative bg-gray w-full h-[14rem] lg:h-[28rem] mt-[9rem]">
              <Image
                src={chat}
                alt="Hero Image"
                layout="fill"
                objectFit="cover"
                placeholder="blur"
                objectPosition="100%"
              />
            </div>
          </div>
          <div className="mt-[7rem] lg:mt-[18rem] px-8">
            <h2 className="text-4xl lg:text-5xl font-semibold">About Us</h2>
            <div className="flex mt-7 lg:mt-14 flex-col lg:flex-row gap-4 lg:gap-0">
              <div className="bg-gray-50 lg:w-[25.9rem] h-[12rem] lg:h-[16.4rem] relative lg:mr-16">
                <Image
                  src={africasasa}
                  alt="Hero Image"
                  layout="fill"
                  objectFit="cover"
                />
              </div>
              <div className="max-w-[22rem]  text-lg leading-8 lg:mr-8">
                AfricaSasa is a marketplace that provides easy access to locally
                relevant market information such as events happening around them
                as well as product or service information from local merchants.
                We are currently focused on the Nairobi market and presently
                showcase events happening
              </div>
              <div className="max-w-[22rem] text-lg leading-8">
                in Nairobi arranged in a user friendly and easy way with the
                goal of making sure that no event that would interest our users
                passes without them noticing it. We also make it easy for event
                organizers to create and post their events online as well as
                issue tickets.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-[20rem] lg:h-[33.5rem] mb-[11.5rem] relative mt-[5rem] lg:mt-[13rem]">
        <div className="absolute h-full w-full bg-opacity-20">
          <Image
            src={party_h}
            alt="Hero Image"
            layout="fill"
            objectFit="cover"
            placeholder="blur"
            objectPosition="100%"
          />
        </div>
        <div className="absolute z-10 top-[2rem] lg:top-[14rem] left-[5.5rem]">
          <div className="font-bold text-4xl lg:text-6xl text-white mb-[5.5rem] max-w-[35rem]">
            Start Listing Your Event Now
          </div>
          <div>
            <Link href="/user/register">
              <a className="text-lg font-semibold bg-white text-black visited:text-black px-12 py-4 rounded-lg  border-2 border-white hover:bg-gray-100">
                Get Started
              </a>
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

const Numbering = ({ value, className }) => (
  <div
    className={cn(
      'bg-[#FEC00F] rounded-full h-[4rem] lg:h-[7.4rem] lg:w-[7.4rem] w-[4rem] font-bold text-4xl lg:text-7xl text-white flex items-center justify-center z-40',
      className
    )}
  >
    {value}
  </div>
);

const Point = ({ label }) => (
  <div className="flex items-center space-x-2 text-[#696871] text-lg">
    <span className="material-icons text-[#FEC00F]">&#xe876;</span>
    <span>{label}</span>
  </div>
);

export default Home;
