import React from 'react';

import BrandLayout from '../components/brand/BrandLayout';
import Home from '../components/brand/Home';

export default function HomePage() {
  return (
    <BrandLayout>
      <Home />
    </BrandLayout>
  );
}
